import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

import SEO from '../components/seo';

import { Item, Menu } from '../styles/styles.js';

const Porcoes = ({ data }) => {
  return (
    <>
      <SEO
        title="Lanches"
        keywords={[
          `Lanches`,
          `Batata`,
          `Batata com Bacon`,
          `filé de frango`,
          `Porção de Calabresa`,
        ]}
      />
      <Menu className="menu">
        <Slide left cascade>
          <h1 className="menu-title">Lanches</h1>
        </Slide>

        <Fade left>
          <div className="item-image">
            <Img
              fluid={data.lanches.childImageSharp.fluid}
              alt="Lanches vários sabores"
            />
          </div>
        </Fade>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">Burguer União</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">35,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Pão de hambúrguer, molho barbecue, calabresa, tomate, alface,
            hambúrguer, salsicha, bacon, ovo, presunto e queijo.
          </p>
        </Item>
      </Menu>
    </>
  );
};

export const query = graphql`
  query {
    lanches: file(relativePath: { eq: "lanches.png" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Porcoes;
